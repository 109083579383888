<template>
  <div>
    <div
      class="flix-flex flix-flex-center flix-gap-5"
      style="margin-bottom: 10px; justify-content: flex-start"
    >
      <a
        v-for="(short, key) in services[index].short"
        @click.prevent="removeTag(key)"
        href="#"
        class="flix-flex flix-flex-inline flix-gap-5 flix-btn"
        :key="key"
        >{{ short }}
        <icon v-if="services[index].short.length > 1" id="circle-remove"
      /></a>
    </div>
    <div
      class="flix-flex flix-flex-center flix-gap-10"
      style="justify-content: flex-start"
    >
      <form v-if="newTag" @submit.prevent="setTag">
        <input
          v-model="tag"
          type="text"
          class="flix-form-control"
          style="width: 200px"
          :placeholder="$t('services.tags.add')"
        />
      </form>
      <select
        v-if="!newTag && open"
        class="flix-form-control"
        @change.prevent="setSelect"
      >
        <option>- {{ $t("services.tags.select") }} -</option>
        <optgroup label="Neu">
          <option value="newTag">{{ $t("services.tags.add") }}</option>
        </optgroup>
        <optgroup label="Bestehend" v-if="getAllTags().length">
          <option v-for="(tag, index) in getAllTags()" :key="index">{{
            tag
          }}</option>
        </optgroup>
      </select>
    </div>
    <div class="flix-form-group">
      <a
        href="#"
        @click.prevent="addTag"
        class="flix-html-a flix-html-small flix-text-success"
      >
        <span v-if="open && !newTag">{{ $t("services.tags.close") }}</span>
        <span v-else-if="newTag && !tag">{{ $t("services.tags.close") }}</span>
        <span v-else-if="newTag">{{ $t("services.tags.save") }}</span>
        <span v-else>{{ $t("services.tags.add") }}</span>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    index: Number
  },
  data() {
    return {
      services: JSON.parse(JSON.stringify(this.$store.state.service.unsaved)),
      newTag: false,
      open: false,
      tag: ""
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setSave() {
      this.$store.commit("service/prefetch", this.services);
    },
    getAllTags() {
      var r = [];
      var services = JSON.parse(
        JSON.stringify(this.$store.state.service.unsaved)
      );
      var check = this.services[this.index].short;
      Object.keys(services).forEach(function(index) {
        Object.keys(services[index].short).forEach(function(tag) {
          if (
            check.indexOf(services[index].short[tag]) === -1 &&
            r.indexOf(services[index].short[tag]) === -1
          ) {
            r.push(services[index].short[tag]);
          }
        });
      });
      r.sort();
      return r;
    },
    removeTag(key) {
      if (this.services[this.index].short.length <= 1) {
        return false;
      }
      this.services[this.index].short.splice(key, 1);
      this.setSave();
    },
    addTag() {
      this.services = JSON.parse(
        JSON.stringify(this.$store.state.service.unsaved)
      );

      if (this.tag) {
        this.setTag();
      }
      if (this.open) {
        this.open = false;
        this.newTag = false;
        return false;
      }
      if (!this.tag && this.getAllTags().length === 0) {
        this.open = true;
        this.newTag = true;
        return false;
      }
      this.newTag = false;
      this.open = true;
    },
    setTag() {
      if (this.tag) {
        this.services[this.index].short.push(this.tag);
        this.setSave();
      }
      this.tag = "";
      this.open = false;
      this.newTag = false;
    },
    setSelect(tag) {
      if (tag.target.value === "newTag") {
        this.newTag = true;
      } else {
        this.tag = tag.target.value;
        if (this.tag) {
          this.setTag();
        }
      }
    }
  }
};
</script>
<style lang="sass" scoped></style>
